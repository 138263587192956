<template>
  <welcome-message
    custom-background-style='background: linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%);' />
</template>

<script>
import WelcomeMessage from '@/views/demo/Welcome.vue'

export default {
  name: 'Welcome',
  components: {
    WelcomeMessage
  },
}
</script>
